const enTranslations = {

	/**
	  * Global
	  */
	enviar: "Send",
	cancelar: "Cancel",
	continuar: "Continue",
	guardar: "Save",
	volver: "Back",
	editar: "Edit",
	cargando: "Loading ...",
	nuevo: "New",

	/**
	  * Nav
	  * Shop/components/Nav y FilterNav
	  */
	notProducts: "No Products Yet",
	search: "Search",
	myShopping: "My Shopping",
	hello: "Hello",
	messages: "Messages",
	inquieryBasket: "Inquiery Basket",
	products: "Products",
	aboutUs: "About us",
	homePage: "Home",
	sign_in: "Sign In",
	join: "Join",
	logout: "Log Out",
	//FilterNav
	filtros: "Filter",
	cerrar: "Close",
	categorias: "Categories",
	departamentos: "Departaments",
	ordenar_por: "Order by",
	precio_bajo_alto: "Price low-high",
	precio_alto_bajo: "Price high-low",
	mas_nuevo: "Newest",

	/**
	  * Home
	  * Shop/components/Home
	  */
	bestSeller: "Best Seller",
	new: "New",
	recentlyView: "Recently Viewed",

	/**
	  * Footer
	  * Shop/components/Footer
	  */
	nombre: "Name",
	correo: "Email",
	mensaje: "Message",
	servicioCliente: "Customer Service",
	envianosCorreo: "Send us an email",
	uneteAFamilia: "Join the family to receive news and updates about our products",
	quienesSomos: "Who we are?",
	avisoPrivacidad: "Privacy Policy",
	trabajaConNosotros: "Work with us",
	paraClientes: "For Customers",
	pagosEnvios: "Payments and Shipping",
	politicasCompra: "Purchase Policies",
	redesSociales: "Social Network",
	todosLosDerechosReservados: "All rights reserved",

	/**
	  * Banner2.js
	  * Shop/components/Home/BAnner2.js
	  */
	enero: "January",
	febrero: "February",
	marzo: "March",
	abril: "April",
	mayo: "May",
	junio: "June",
	julio: "July",
	agosto: "August",
	septiempre: "September",
	octubre: "October",
	noviembre: "November",
	diciembre: "December",
	increible_con_ofertas_increibles: "incredible with incredible offers",
	multiples_soluciones_demandas_especiales: "Multiple solutions for your special demands",
	categorias: "Categories",
	servicios: "Services",
	proveedores_lideres: "Leading Suppliers",
	centro: "CENTER",

	/**
	  * Modals
	  * Shop/components/Modals
	  */
	//ModalAdress.js
	agregar_direccion: "Add address",
	direccion: "Address",
	nombre_completo: "Full Name",
	numero_exterior: "Exterior Number",
	numero_interior: "Interior Number",
	cp: "Postal Code",
	estado: "State",
	provincia: "Province",
	ciudad: "City",
	selecciona_colonia: "Select your neighborhood",
	colonia: "Neighborhood",
	instrucciones_entrega: "Delivery Instructions",
	telefono: "Phone",
	guardar: "Save",
	//ModalContactNow.js
	mensaje: "Message",
	mensaje_enviado: "Message sent",
	enviar: "Send",
	mensaje_para: "Message for",
	//ModalCotizar.js
	cotizacion_enviada: "Quote Sent",
	destino_de_la_mercancia: "Destination of the goods",
	elige_pais: "Choose your country",
	pais: "Country",
	elige_ciudad: "Choose your city",
	ciudad: "City",
	selecciona_servicios_cotizar: "Select services to quote",
	si: "Yes",
	no: "Not",
	cargo_internacional: "International Freight",
	seguro: "Insurance",
	despacho_aduana: "Customs Clearance",
	entrega_en_destino: "Delivery at Destination",
	asesoria: "Consulting",

	/**
	  * ProductCard
	  * Shop/components/ProductCard
	  */
	precio: "Price",
	pieza: "Piece",
	orden: "Order",
	piezas: "Pieces",

	/**
	  * Proximamente
	  * Shop/components/Proximamente
	  */
	en_construccion: "Under Construction",
	vuelve_mas_tarde: "Come back later to enjoy everything we have for you",

	/**
	  * 404
	  * Shop/pages/404/index.js
	  */
	lo_sentimos: "SORRY",
	no_pudimos_encontrar_pagina: "we couldn't find the page you were looking for",
	intenta_usar_barra_busqueda: "Try using the search bar or visit the",
	pagina_principal: "Home Page",

	/**
	  * /aboutus
	  */
	//Shop/pages/aboutus/Components/Home/index.js y Shop/pages/proveedor/Components/Home/index.js
	mensaje_enviado: "Message sent",
	revisa_mensajes_en_panel: "Check your messages in the panel",
	aboutUs: "About Us",
	perfil_de_la_empresa: "Company Profile",
	capacidad_comercial: "Commercial Capacity",
	capacidad_de_produccion: "Production Capacity",
	ver_empresa: "View company",
	contactar_al_proveedor: "Contact the supplier",
	chatea_con_el_proveedor: "Chat with the supplier",
	tipo_negocio: "Business Type",
	productos_principales: "Main Products",
	area_de_planta: "Plant Area",
	certificacion_del_sistema: "Management System Certification",
	terminos_de_entrega: "Delivery Terms",
	tipo_de_pago: "Payment Type",
	porcentaje_de_exportacion: "Export Percentage",
	mercados_principales: "Main Markets",
	puerto_cercano: "Nearest Port",
	numero_de_licencia_de_exportacion: "Export License Number",
	archivo_de_licencia_de_exportacion: "Export License File",
	direccion_operativa_de_la_empresa: "Company Operating Address",
	total_de_empleados: "Total Employees",
	tamaño_de_oficina: "Office Size",
	produccion_anual_de_productos_principales: "Annual Production of Main Products",
	por_encima: "Above",
	album_de_la_empresa: "Company Album",
	verificado: "Verified",
	certificados: "Certificates",
	elementos: "Items",

	/**
	  * /account_suspended
	  */
	//Shop/pages/account_suspended/index.js
	cuenta_suspendida: "Account suspended",

	/**
	  * /bolsa_trabajo
	  */
	//Shop/pages/bolsa_trabajo/Components/Bolsa/index.js
	si_consideras_formar_parte_equipo: "If you believe you can be part of the team, we invite you to share your details with us",
	nivel_estudios: "Education Level",
	puesto_solicitado: "Position Applied For",
	nota: "Note",

	/**
	  * /cat
	  */
	//Shop/pages/cat/Components/Categoria/index.js
	grupos_de_productos: "Product Groups",

	/**
	  * /faq
	  */
	//Shop/pages/faq/Components/FAQ/index.js
	preguntas_frecuentes: "Frequently Asked Questions",

	/**
	  * /login
	  */
	//Shop/pages/login/Components/Login/index.js
	credenciales_no_coinciden: "The credentials do not match our database, please try again later",
	crea_una_cuenta_y_disfruta: "Create an account and enjoy the benefits of",
	contraseña: "Password",
	entrar: "Log In",
	registrate_aqui: "Sign up here",
	olvide_mi_contraseña: "Forgot my password",
	no_tienes_cuenta: "Don\'t have an account?",

	/**
	  * /messages
	  */
	//Shop/pages/messages/Components/Messages/index.js
	miembro_diamante: "Diamond Member",
	desde: "Since",
	proveedor_auditado: "Audited Supplier",
	visto: "Viewed",
	enviado: "Sent",
	recibido: "Received",

	/**
	  * /micarrito
	  */
	//Shop/pages/micarrito/Components/Carrito/index.js
	algunos_articulos_en_carrito_estan_agotados: "Some items in your cart are out of stock",
	proceder_al_pago: "Proceed to checkout",
	tu_carrito_esta_vacio: "Your cart is empty",
	sin_atributo: "No attribute",
	sin_descripcion: "No description",
	disponible: "Available",
	stock: "Stock",
	comprar: "Buy",
	eliminar: "Remove",
	guardar_mas_tarde: "Save for later",
	comprar_articulos_seleccionados: "Buy selected items",
	//Shop/pages/micarrito/Components/StattusBar/index.js
	CARRITO: "CART",
	DIRECCION: "ADDRESS",
	OPCIONES_RECOLECCION: "PICKUP OPTIONS",
	PAGO: "PAYMENT",
	FINALIZAR: "FINALIZE",
	/*
	   *
	   * /micarrito/direccion
	   */
	//Shop/pages/micarrito/direccion/Components/Direccion/index.js
	direccion_eliminada: "Address deleted",
	telefono_incorrecto: "Incorrect phone number",
	no_dejes_pasar_el_momento: "Don\'t let this moment pass",
	registrate: "Sign up",
	HOLA: "HELLO",
	recolectar: "Collect",
	direccion_recoleccion: "Collection address",
	direccion: "Address",
	detalles_quien_recoge: "Details of who is picking up the package",
	elige_direccion_envio: "Choose your shipping address",
	recoger_en_sucursal: "Pick up at branch?",
	añadir_direccion: "Add address",
	enviar_paquete_a: "Send package to",
	//Shop/pages/micarrito/direccion/Components/Direccion/new.js
	calle: "Street",
	opcional: "Optional",
	instrucciones_entrega: "Delivery Instructions",
	/*
	   *
	   * /micarrito/envio
	   */
	//Shop/pages/micarrito/envio/Components/Envio/index.js
	recogelo_tu_mismo: "Pick it up yourself",
	ver_mapa: "View map",
	horario_de_atencion: "Business hours",
	hora: "Time",
	dias: "Days",
	economico: "Economical",
	paquete: "Package",
	elija_una_opcion: "Choose an option",
	/*
	   *
	   * /micarrito/finaliza
	   */
	//Shop/pages/micarrito/finaliza/Components/Finalizar/index.js
	fecha_desconocida_consulte_su_guia: "Unknown date, please check your guide at fedex.com",
	gracias_por_tu_pedido: "Thank you for your order",
	tu_pedido_se_hizo_exitosamente: "Your order was successfully placed",
	fecha_entrega_estimada: "Estimated delivery date",
	despues_de_validar_el_pago: "After payment validation",
	pedido: "Order",
	imprimir: "Print",
	comprobante: "Receipt",
	imprime_o_muestra_este_comprobante: "Print or show this receipt at our branch, along with an official ID to pick up your order",
	articulos: "Items",
	codigo: "Code",
	cantidad: "Quantity",
	descubre_mas_productos: "Discover more products",
	/*
	   *
	   * /micarrito/pago
	   */
	//Shop/pages/micarrito/pago/Components/Pago/index.js
	seleccione_articulos: "Select Items",
	cupon_aplicado: "Coupon applied",
	cupon_no_valido: "Invalid coupon",
	selecciona_un_metodo_pago: "Select a payment method",
	tarjeta_de_credito: "Credit card",
	tarjeta_de_debito: "Debit card",
	paga_en_oxxo: "Pay at OXXO",
	resumen: "Summary",
	cupon: "Coupon",
	descuento_no_aplicable_a_gastos_envio: "The discount does not apply to shipping costs.",
	canjear_cupon: "Redeem coupon",
	pago_procesado_de_forma_segura: "Payment processed securely",
	problemas_con_tu_pago: "Problems with your payment?",
	verifica_que_tengas_fondos: "Check that you have funds on your card",
	verifica_que_informacion: "Verify that the information is correct",
	recuerda_activar_3d_en_tu_banco: "Remember to activate 3D Secure with your bank to make online payments",
	proveedor_de_envio: "Shipping provider",
	fecha_entrega_estimada: "Estimated delivery date",
	nombre_del_producto: "Product name",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/oxxoPay.js nota: oxxo solo esta disponible en mexico
	recuerda_que_tienes_3_dias_para_pagar_en_oxxo: "Remember you have 3 days from today to pay at OXXO",
	ficha_digital_no_es_necesario_imprimir: "Digital slip, No need to print",
	monto_pagar: "Amount to pay",
	oxxo_cobra_comision: "OXXO will charge an additional fee when making the payment.",
	click_para_guardar: "Click to Save",
	REFERENCIA: "REFERENCE",
	instrucciones: "Instructions",
	guardar_esta_ficha: "Save this slip",
	acude_a_oxxo: "Go to the nearest OXXO store",
	indica_en_caja_que_quieres_pagar_con_oxxopay: "Tell the cashier you want to pay with OXXOPay",
	muestra_al_cajero_referencia: "Show the cashier the reference number",
	realiza_pago_correspondiente: "Make the corresponding payment",
	conserva_comprobante: "Keep your payment receipt",
	encuentra_aqui: "Find here",
	al_completar_pago_recibiras_correo_de_confirmacion: "After completing these steps, you will receive a confirmation email from",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/payPal.js
	paypal_locale: "en_US",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/santander.js
	agregar_tarjeta: "Add card",

	/*
	   *
	   * /miperfil
	   */
	//Shop/pages/miperfil/index.js
	mi_cuenta: "My account",
	apodo: "Nickname",
	mis_pedidos: "My orders",
	mis_direcciones: "My addresses",
	mi_perfil: "My profile",
	cerrar_sesion: "Log out",
	/*
	   *
	   * /miperfil/datos
	   */
	//Shop/pages/miperfil/datos/Components/MiPerfil/index.js
	tus_datos_se_actualizaron: "Your data has been updated successfully",
	verifique_sus_datos: "Verify your data",
	ingresa_nuevos_datos: "Enter the new data",
	confirmar_contraseña: "Confirm password",
	las_contraseñas_no_coinciden: "The passwords do not match",
	/*
	   *
	   * /miperfil/misdirecciones
	   */
	//Shop/pages/miperfil/misdirecciones/Components/Direcciones/index.js
	direccion_agregada: "Address added",
	direccion_actualizada: "Address updated",
	direccion_eliminada: "Address deleted",
	enviar_a: "Send to",

	/*
	   *
	   * /pedidos
	   */
	//Shop/pages/pedidos/Components/Pedidos/index.js
	carrito_actualizado: "Cart updated",
	no_contamos_con_numero_de_productos_en_stock: "We don't have that number of products in stock",
	pedidos_en_curso: "Orders in progress",
	pedidos: "Orders",
	cotizaciones: "Quotes",
	no_se_encontraron_pedidos: "No orders found",
	llegando_el: "Arriving on",
	consulta_en_24_horas_progreso: "Check in 24 hours. The progress",
	entrega_en_sucursal: "Store pickup",
	producto: "Product",
	descripcion: "Description",
	pedido_el: "Order on",
	pagado: "Paid",
	paquete_en_camino: "Package on the way",
	paquete_listo_para_recoger: "Package ready for pickup",
	paquete_entregado: "Package delivered",
	seguir_pedido: "Track order",
	recoger_en: "Pick up at",
	ver_detalles: "View details",
	evaluar_producto: "Rate product",
	precio_del_envio: "Shipping price",
	entregado: "Delivered",
	pendiente: "Pending",
	cotizacion: "Quote",
	producto_cotizado: "Quoted product",
	proveedor: "Supplier",
	archivo: "File",

	/*
	   *
	   * /pedidos/pedido
	   */
	//Shop/pages/pedidos/pedido/Components/Pedido/index.js
	datos_de_envio: "Shipping details",
	tipo_de_envio: "Shipping type",
	total: "Total",
	subtotal: "Subtotal",
	numero_de_guia: "Tracking number",
	recoleccion: "Collection",

	/*
	   *
	   * /product
	   */
	//Shop/pages/product/Components/Producto/index.js
	lo_sentimos_no_puedes_agregar_mas_productos: "Sorry, you cannot add more products of this type, you have exceeded the stock",
	lo_sentimos_no_hay_stock_disponible: "Sorry, there is currently no stock available",
	compra_desde_mexico: "Shop from Mexico",
	producto_no_disponible: "Product not available",
	numero_minimo_de_piezas: "Minimum number of pieces",
	añadir_al_carrito: "Add to cart",
	solicitar_cotizacion_especial: "Request special quote",
	cantidad_a_cotizar: "Quantity to quote",
	cotizar: "Quote",
	comprar_ya: "Buy now",
	envio_desde: "Shipping from",
	vendido_por: "Sold by",
	acerca_de_este_producto: "About this product",
	sin_descripcion: "No description",
	colores: "Colors",
	plazo_de_entrega: "Delivery time",
	tiempo: "Time",
	personalizacion: "Customization",
	materiales: "Materials (what the product is made of)",
	protecciones: "Protections",
	protege_tus: "Protect your",
	detalles_del_producto: "Product details",
	descripcion_general: "General description",
	numero_de_modelo: "Model number",
	muestra: "Sample",
	condiciones_de_pago: "Payment terms",
	tiempo_de_entrega: "Delivery time",
	especificaciones: "Specifications",
	origen: "Origin",
	material: "Material",
	marca_comercial: "Trade brand",
	capacidad_de_produccion: "Production capacity",
	uso_especial: "Special use",
	detalles_de_embalaje: "Packaging details",
	codigo_hs: "HS code",
	descripcion_detallada: "Detailed description",
	//Shop/pages/product/Components/Producto/Resenia.js
	calificacion: "Rating",
	calificacion_por_estrellas: "Star rating",
	de: "of",
	comentarios: "Comments",
	reportar: "Report",

	/*
	   *
	   * /recuperar
	   */
	//Shop/pages/recuperar/Components/Recuperar/index.js
	te_enviamos_un_correo_de_recuperacion: "We have sent you a recovery email",
	ingresa_la_nueva_contraseña_para: "Enter the new password for",
	// /recuperar/token
	link_expirado: "Link expired",
	/*
	   *
	   * /registro
	   */
	//Shop/pages/registro/Components/Registro/index.js
	ya_tienes_cuenta: "Do you already have an account?",

	/*
	   *
	   * /reviews
	   */
	//Shop/pages/reviews/index.js
	mis_comentarios: "My Comments",
	evaluar_producto: "Rate product",
	gracias_por_el_comentario: "Thank you for the comment, it has been submitted",
	tu_comentario_esta_siendo_procesado: "Your comment is being processed, this may take a while. We appreciate your patience",
	calificacion_general: "Overall rating",
	titulo_del_comentario: "Comment title",
	agregar_un_comentario: "Add a comment",

	/**
	  * /search
	  * Shop/pages/search/Components/Search/index.js
	  */
	desea_eliminar_historial_busquedas: "Do you want to delete your search history?",
	busqueda_reciente: "Recent search",
	no_se_han_realizado_busquedas: "No recent searches have been made",
	/** * /search/keyword
	  * Shop/pages/search/keyword/Components/ResultSearch/index.js
	  */
	lo_sentimos_no_hay_resultados: "Sorry, no results were found, try another search",
	producto_destacado: "Featured product",

	/**
	  *
	  * Admin
	  *
	  */
	//Admin/components/dashboard.js
	bienvenido_a_tu_panel: "Welcome to your online store dashboard",
	tu_pagina_es: "Your page is",
	para_configurar_tu_dominio: "To set up your custom domain name, go to Settings and click on the Domains option",
	abrir: "Open",
	bienvenido: "Welcome",
	saldos: "Balances",
	resumen_de_saldos: "Balance Summary",
	historial_de_pagos: "Payment History",
	visitas: "Visits",
	visitas_diarias: "Daily Visits",
	inventario: "Inventory",
	dropshiping: "Dropshipping",
	mi_catalogo: "My Catalog",
	cotizaciones: "Quotes",
	solicitudes_de_cotizacion: "Quotation Requests",
	configuraciones: "Configurations",
	gestionar_informacion_de_la_empresa: "Manage Company Information",
	configurar_carrusel: "Configure Carousel",
	configurar_sitio_web: "Configure Website",
	direccion_de_recoleccion: "Pickup Address",
	mi_cuenta_bancaria: "My Bank Account",
	dominios: "Domains",
	politicas: "Policies",
	ayuda: "Help",
	suscripcion: "Subscription",
	estado_de_la_suscripcion: "Subscription Status",
	importante: "Important",
	para_acceder_a_todas_las_funciones: "To access all functions and upload products, it is essential to complete your company information in the SETTINGS and MANAGE MY INFORMATION sections.",
	//Admin/components/Ayuda/faq.js
	actualizado: "Updated",
	estas_seguro_que_deseas_borrar: "Are you sure you want to delete?",
	eliminado: "Deleted",
	nuevo_faq: "New FAQ",
	editar_faq: "Edit FAQ",
	pregunta: "Question",
	respuesta: "Answer",
	opciones: "Options",
	guardado: "Saved",
	//Admin/components/Ayuda/glosario.js
	palabra: "Word",
	definicion: "Definition",
	glosario: "Glossary",
	editar_del_glosario: "Edit from glossary",
	//Admin/components/Comentarios/Comentarios.js
	evaluaciones_de: "Evaluations of",
	seleccionar_calificacion: "Select Rating",
	estrellas: "Stars",
	mostrar_visibles: "Show Visible",
	borrar_filtros: "Clear Filters",
	oculto: "Hidden",
	visible: "Visible",
	fecha_de_publicacion: "Publication Date",
	este_comentario_tiene_pendiente_reportes: "This comment has one or more pending reports",
	eliminar_comentario: "Delete Comment",
	//Admin/components/Comentarios/ComentariosList.js
	evaluaciones: "Evaluations",
	ver_producto: "View Product",
	//Admin/components/Configuracion/CompanyInformation/index.js
	informacion_basica_de_empresa: "Basic Company Information",
	detalles_de_productos: "Product Details",
	informacion_completa_de_empresa: "Complete Company Information",
	personas_de_contacto: "Contact Persons",
	informacion_de_exportacion: "Export Information",
	centro_de_certificados: "Certificate Center",
	//Admin/components/Configuracion/CompanyInformation/step1_CompanyBasicInfo.js
	fabricante: "Manufacturer",
	compañia_comercial: "Trading Company",
	oficina_de_compras: "Buying Office",
	agente: "Agent",
	distribuidor_mayorista: "Distributor/Wholesaler",
	ministerio_de_gobierno: "Government Ministry/Office/Commission",
	asociacion: "Association",
	servicio_comercial_etc: "Commercial Service (transport, finance, travel, advertising, etc.)",
	nombre_de_la_empresa: "Company Name",
	licencia_comercial: "Business License",
	archivo_de_licencia_comercial: "Business License File",
	numero: "Number",
	certificado_de_registro_fiscal: "Tax Registration Certificate",
	archivo_certificado_de_registro_fiscal: "Tax Registration Certificate File",
	calle_y_numero: "Street and Number",
	comprobante_de_domicilio: "Proof of Address",
	direccion_de_fabrica: "Factory Address",
	//Admin/components/Configuracion/CompanyInformation/step2_productDetails.js
	industria_principal: "Main Industry",
	otros_productos_que_vende: "Other Products You Sell",
	//Admin/components/Configuracion/CompanyInformation/step3_CompanyCompletedInfo.js
	ventajas_de_la_empresa: "Company Advantages",
	ventajas_de_la_empresa_min_caracteres: "Company Advantages Minimum Characters",
	introduccion_de_la_empresa: "Company Introduction",
	introduccion_de_la_empresa_min_catacteres: "Company Introduction Minimum Characters",
	año_de_registro_de_la_empresa: "Company Registration Year",
	menos_que: "Less than",
	mas_de: "More than",
	personas: "People",
	url_sitio_web_de_empresa: "Company Website URL",
	propietario_legal: "Legal Owner",
	metros_cuadrados: "Square Meters",
	condiciones_de_entrega_aceptados: "Accepted Delivery Terms",
	tipos_de_pago_aceptados: "Accepted Payment Types",
	logo_de_la_empresa: "Company Logo",
	logo: "Logo",
	fotos_de_la_empresa: "Company Photos",
	haz_clic_o_arrastra: "Click or Drag",
	//Admin/components/Configuracion/CompanyInformation/step4_ContactPerson.js
	contacto: "Contact",
	posicion: "Position",
	//Admin/components/Configuracion/CompanyInformation/step5_ExportInfo.js
	nombre_de_la_empresa_exportadora: "Exporter Company Name",
	america_del_norte: "North America",
	sudeste_asiatico: "Southeast Asia",
	oriente_medio: "Middle East",
	america_central: "Central America",
	asia_del_sur: "South Asia",
	america_del_sur: "South America",
	africa: "Africa",
	asia_oriental: "East Asia",
	europa_del_norte: "Northern Europe",
	europa_del_este: "Eastern Europe",
	oceania: "Oceania",
	europa_occidental: "Western Europe",
	europa_del_sur: "Southern Europe",
	//Admin/components/Configuracion/CompanyInformation/step6_Certificates.js
	certificado_nuevo: "New Certificate",
	nombre_del_certificado: "Certificate Name",
	foto_del_certificado: "Certificate Photo",
	foto: "Photo",
	//Admin/components/Configuracion/Recolecciones/index.js
	horario_fuera_de_rango: "Out-of-Range Schedule",
	seleccionar_dias: "Select Days",
	lunes: "Monday",
	martes: "Tuesday",
	miercoles: "Wednesday",
	jueves: "Thursday",
	viernes: "Friday",
	sabado: "Saturday",
	selecciona_un_dia_de_la_semana: "Select a Day of the Week",
	dias_seleccionados: "Selected Days",
	nombre_del_contacto: "Contact Name",
	telefono_del_contacto: "Contact Phone",
	nombre_de_la_empresa_lugar: "Company or Place Name",
	//Admin/components/Configuracion/Website/carrusel/Carrusel.js
	datos_incompletos: "Incomplete Data",
	carrusel_de_imagenes: "Image Carousel",
	debes_tener_al_menos_una_imagen_en_carrusel: "You must have at least one image available for the carousel",
	tamaño_recomendado: "Recommended Size",
	fotos: "Photos",
	arrastre_en_orden_de_visualizacion: "Drag in the order you want to display on the main page",
	todos_los_cambios_se_reflejaran_al_presionar_guardar: "All changes will be reflected when you press the save button",
	//Admin/components/Configuracion/Website/carrusel/SliderImg.js
	boton: "Button",
	titulo_del_boton: "Button Title",
	limite_de_caracteres: "Character Limit",
	url_del_boton: "Button URL",
	color_textos: "Text Color",
	titulo: "Title",
	//Admin/components/Configuracion/Website/websiteconfigs/index.js
	url_de_red_social_incorrecta: "Incorrect Social Media URL",
	configuraciones_de_tu_tienda: "Store Settings",
	sitio_web: "Website",
	activar_sitio_web_y_mostrarlo_online_desactivar: "Activate website to display it online; deactivate to show 'under construction'",
	activado: "Activated",
	desactivado: "Deactivated",
	datos_de_tu_ecommerce: "Ecommerce Data",
	idioma: "Language",
	nombre_de_tu_tienda: "Store Name",
	telefono_de_atencion: "Support Phone",
	correo_de_atencion: "Support Email",
	nombre_menu_principal_ejem: "Main Menu Name (e.g., Departments)",
	el_nombre_del_menu_principal_aparece_en_nav: "The main menu name appears in the navigation bar",
	seccion_quienes_somos: "About Us Section",
	imagenes: "Images",
	para_mejor_experiencia_usa_png_sin_fondo: "For a better experience, use transparent PNG",
	iconos_de_carrito: "Cart Icons",
	iconos_de_verificacion: "Verification Icons",
	barra_de_navegacion: "Navigation Bar",
	color_texto_barra_de_navegacion: "Navigation Bar Text Color",
	color_principal: "Primary Color",
	color_secundario: "Secondary Color",
	color_textos_informativos: "Informative Text Color",
	color_de_resaltado: "Highlight Color",
	color_de_fondo_pie_pagina: "Footer Background Color",
	color_de_texto_pie_pagina: "Footer Text Color",
	dia_inicio: "Start Day",
	dia_fin: "End Day",
	hora_inicio: "Start Time",
	hora_fin: "End Time",
	imagenes_publicitarias: "Advertising Images",
	imagen: "Image",
	//Admin/components/Cotizaciones/cotizacion.js
	cotizacion: "Quotation",
	fecha_solicitud: "Request Date",
	informacion: "Information",
	pais_destino: "Destination Country",
	ciudad_destino: "Destination City",
	//Admin/components/Cotizaciones/index.js
	codigo_del_producto: "Product Code",
	fecha: "Date",
	//Admin/components/Inventarios/productos/edit.js y new.js
	seguro_que_deseas_archivar: "Are you sure you want to archive?",
	actualizar_producto: "Update Product",
	industria: "Industry",
	departamento: "Department",
	categoria: "Category",
	nombre_del_producto: "Product Name",
	descripcion_breve: "Short Description",
	uso: "Usage",
	modelo: "Model",
	los_modelos_son_generados_por_el_sistema: "Models are system-generated",
	palabras_clave: "Keywords to facilitate product search (e.g., Black, Winter, Boots)",
	atributos: "Attributes",
	sin_atributo: "No Attributes",
	tamaño: "Size",
	ancho: "Width",
	capacidad: "Capacity",
	tipo: "Type",
	color: "Color",
	sub_atributos_para: "Sub-attributes for",
	codigo_de_barras: "Barcode",
	registrar: "Register",
	completar_datos_para: "Complete Data for",
	colores: "Colors",
	tamaño_de_pieza: "Piece Size (How big is the Garment or accessory)",
	largo_de_la_pieza: "Length of the piece",
	ancho_de_la_pieza: "Width of the piece",
	alto_de_la_pieza: "Height of the piece",
	peso_de_la_pieza: "Weight of the piece",
	caracteristicas: "Features",
	datos_tecnicos: "Technical data",
	fotos_del_producto: "Product photos",
	el_tamaño_debe_ser_menor_5_mb: "The image file size must be less than 5 MB",
	formatos_admitidos: "Accepted formats",
	el_tamaño_recomendado_es: "The recommended image size is over",
	las_imagenes_deben_ser_claras: "Images must be clear and easy to see at a glance for buyers",
	añadir_una_marca_a_tu_imagen_puede_evitar_robos: "Adding a watermark to your image can prevent theft",
	imagenes_de_fotos_principales: "Main photos used in search. The image must be more than 350 x 350 pixels. The photo size ratio should be between 3:4 and 4:3",
	foto_portada: "Cover Photo",
	informacion_de_embalaje: "Packaging information",
	caja_de_carton: "Cardboard box",
	bolsa: "Bag",
	costal: "Sack",
	tipo_de_embalaje: "Type of packaging",
	foto_del_embalaje: "Packaging photo",
	piezas_por_embalaje: "Pieces per packaging",
	numero_entero: "Integer",
	embalaje_interno: "Internal packaging",
	largo: "Length",
	numero_decimal: "Decimal number",
	ancho: "Width",
	alto: "Height",
	metro_cubico: "Cubic meter",
	peso_bruto: "Gross weight",
	peso_neto: "Net weight",
	informacion_de_compra: "Purchase information",
	plazo_de_entrega_precio: "Delivery time price",
	unidad_de_venta: "Unit of sale",
	cantidad_minima_de_pedido: "Minimum order quantity",
	rango_minimo: "Minimum range",
	rango_maximo: "Maximum range",
	plazo_de_entrega_minimo: "Minimum delivery time",
	plazo_de_entrega_maximo: "Maximum delivery time",
	unidad: "Unit",
	muestra: "Sample",
	precio_de_muestra: "Sample price",
	opciones_de_pago: "Payment options",
	protecciones: "Protections",
	personalizar: "Customize",
	capacidad_mensual: "Monthly capacity",
	contenedores: "Containers",
	informacion_de_recoleccion: "Collection information",
	archivar: "Archive",
	el_campo_uso_es_obligatorio: "The usage field is mandatory",
	el_campo_keywords_es_obligatorio: "The keywords field is mandatory",
	//Admin/components/Inventarios/catalogo.js
	añadir_stock: "Add stock",
	producto_restaurado: "Restored product",
	fecha_de_la_orden: "Order date",
	folio_de_la_orden: "Order number",
	nuevo_producto: "New product",
	catalogo: "Catalog",
	archivados: "Archived",
	productos_sin_departamento: "Products without department",
	historial_de_ordenes: "Order history",
	visible_en_mi_tienda: "Visible in my store",
	variante: "Variant",
	agotado: "Out of stock",
	//Admin/components/Pedidos/listaProductos.js
	total_pagado_sin_costo_envio: "Total paid (excluding shipping cost)",
	cupon_activo: "Active coupon",
	nombre_del_cupon: "Coupon name",
	descuento: "Discount",
	//Admin/components/Pedidos/orderStatus.js
	progreso: "Progress",
	estado: "Status",
	usuario_responsable: "Responsible user",
	piezas_por_display: "Pieces per display",
	piezas_entregadas: "Pieces delivered",
	piezas_en_carrito: "Pieces in cart",
	cancelar_orden: "Cancel order",
	tienda: "Store",
	colaborador: "Collaborator",
	no_ha_sido_tomado: "Not taken",
	ver_estado: "View status",
	//Admin/components/Policies/pagosEnvios.js
	las_politicas_de_pagos_envios_se_han_actualizado: "Payment and shipping policies have been successfully saved",
	pagos_y_envios: "Payments and Shipping",
	las_politicas_de_compra_se_han_actualizado: "Purchase policies have been successfully saved",
	politica_de_compra: "Purchase policy",
	//Admin/components/Wizard/progressNav/ProgressNav.js
	configuracion_de_la_tienda: "Store configuration",
	imagenes_de_carrusel: "Carousel images",
	configurar_redes_sociales: "Configure social media",
	//Admin/components/Wizard/steps/StepFive.js
	seleccione_icono_y_url_facebook: "Select Facebook icon and URL",
	verificar_url_facebook: "Verify Facebook URL must have https://",
	seleccione_icono_y_url_instagram: "Select Instagram icon and URL",
	verificar_url_instagram: "Verify Instagram URL must have https://",
	seleccione_icono_y_url_youtube: "Select YouTube icon and URL",
	verificar_url_youtube: "Verify YouTube URL must have https://",
	todos_los_cambios_se_reflejaran_al_presionar_el_boton: "All your changes or adjustments will be reflected when you click the Finish button at the bottom",
	estas_redes_se_añadiran_al_ecommerce: "These social networks will be added to the website for display on the e-commerce site",
	verifica_http_previamente: "Check that each URL includes http://",
	con_cuantas_redes_sociales_cuentas: "Which social networks do you have?",
	finalizar: "Finish",
	anterior: "Previous",
	//Admin/components/Wizard/steps/StepOne.js
	porfavor_completa_color_barra_navegacion: "Please complete the navigation bar color field.",
	porfavor_completa_color_texto_barra_navegacion: "Please complete the navigation bar text color field.",
	porfavor_completa_color_principal: "Please complete the primary color field.",
	porfavor_completa_color_secundario: "Please complete the secondary color field.",
	porfavor_completa_color_textos_informativos: "Please complete the informational text color field.",
	porfavor_completa_color_resaltado: "Please complete the highlight color field.",
	porfavor_completa_color_footer: "Please complete the footer background color field.",
	porfavor_completa_color_footer_text: "Please complete the footer text color field.",
	todos_los_cambios_se_reflejaran_al_presionar_siguiente: "All your changes or adjustments will be reflected when you press the NEXT button at the bottom.",
	selecciona_un_idioma: "Select a language",
	sube_el_logo_de_tu_tienda: "Upload your store logo",
	siguiente: "Next",
	//Admin/components/Wizard/steps/StepTwo.js
	no_olvides_subir_imagen: "Don't forget to upload an image before proceeding.",
	sube_una_imagen_para_tu_sitio_la_cual_se_utilizara_como_banner_principal: "Upload an image for your site to be used as the main/banner image on the main section.",
	un_banner_es: "A banner is an informational/advertisement image that can have text and a button leading customers to a specific link either inside or outside your e-commerce. It is located at the top of the homepage. At least one needs to be configured.",
	imagenes_con_dimensiones_panoramicas: "Images with panoramic dimensions",
	//Admin/components/Wizard/index.js
	todo_puede_ser_modificado_despues: "Everything can be modified later.",
	esto_es_la_configuracion_inicial: "This is only the initial setup of your store.",
	//Admin/pages/balance/payment-history/index.js
	monto: "Amount",
	cuenta: "Account",
	referencia: "Reference",
	mes: "Month",
	//Admin/pages/balance/summary/index.js
	totales: "Totals",
	saldo_total: "Total Balance",
	pedidos_totales: "Total Orders",
	saldo_listo: "Balance Ready",
	pedidos_entregados: "Delivered Orders",
	saldo_pendiente: "Pending Balance",
	pedidos_pendientes: "Pending Orders",
	//Admin/pages/catalogoglobal/index.js
	tendras_que_volver_agregar_datos_de_precios: "You will need to re-add your data and prices if you wish to re-import.",
	catalogo_global: "Global Catalog",
	proveedor_vendedor: "Supplier/Seller",
	en_mi_tienda: "In my store",
	//Admin/pages/domains/index.js
	video_tutorial_de_dominio_personalizado: "Custom domain video tutorial",
	instrucciones_para_activar_dominio: "Instructions to activate your domain",
	agrega_los_registros_dns_a_tu_dominio: "Add the following DNS records to your domain",
	espera_la_propagacion_dns: "Wait for DNS propagation for about 15 minutes",
	mis_dominios: "My Domains",
	aqui_podras_gestionar_tus_dominios: "Here you can manage your domains linked to your store",
	video_tutorial: "Video tutorial",
	nuevo_dominio: "New Domain",
	añadir_nuevo_dominio: "Add new domain",
	que_dominio_quieres_agregar: "Which domain do you want to add to your account?",
	dominio: "Domain",
	certificado: "Certificate",
	activar: "Activate",
	activo: "Active",
	validacion_pendiente: "Pending validation",
	genera_y_instala_certificado_ssl: "Generate and install SSL/Certificate",
	//Admin/pages/dropshiping/index.js
	error_en_precio: "Price error",
	cantidad_minima_de_pedido_para: "Minimum order quantity for",
	precio_original: "Original price",
	mi_precio: "My price",
	mi_dropshiping: "My Dropshipping",
	precios: "Prices",
	//Admin/pages/entrega/index.js
	entregar: "Deliver",
	nombre_quien_recoge: "Name of the person picking up",
	firma: "Signature",
	volver_a_intentar: "Try again",
	confirmar: "Confirm",
	//Admin/pages/mi-cuenta-bancaria/index.js
	mi_cuenta_bancaria: "My bank account",
	banco: "Bank",
	numero_de_cuenta: "Account number",
	numero_de_tarjeta: "Card number",
	//Admin/pages/suscripcion/plans/premium/index.js
	conekta_locale: "en",
	//Admin/pages/suscripcion/plans/index.js
	planes: "Plans",
	elige_tu_paquete: "Choose your package",
	catalogo_ilimitado_de_productos: "Unlimited product catalog",
	acceso_completo_ilimitado_de_productos: "Unlimited full access to products or categories",
	envio_fedex: "Fedex shipping",
	soporte_basico_por_correo: "Basic support by email or chat",
	dominio_personalizado: "Custom domain",
	ssl_certificado_gratis: "Free SSL/Certificate",
	soporte_personalizado: "Personalized support",
	mensual: "Monthly",
	anual: "Yearly",
	//Admin/pages/suscripcion/index.js
	mi_suscripcion: "My Subscription",
	no_tienes_una_suscripcion: "You don't have an active subscription",
	reactiva_o_obten_suscripcion: "Reactivate or get a subscription",
	siguiente_pago: "Next payment",
	plan: "Plan",
	metodo: "Method",
	tarjeta: "Card",
	tarjeta_exp: "Exp",
	//Admin/pages/ventas/index.js
	sin_pedidos: "No orders",
	pedido_id: "Order ID",
	cliente: "Customer",
	expedidor: "Shipper",
	vendedor: "Seller",
	informacion: "Information",
	listo_para_enviar: "Ready to ship",
	imprimir_guia: "Print guide",
};

export default enTranslations;
