import { lazy } from 'react';

export const DropShipingPageIndex = lazy(() => import('./dropshiping'));
export const CatalogoGlobalPageIndex = lazy(() => import('./catalogoglobal'));
export const VentasPageIndex = lazy(() => import('./ventas'));
export const EntregaPageIndex = lazy(() => import('./entrega'));
export const BalanceSummaryPageIndex = lazy(() => import('./balance/summary'));
export const PaymentHistoryPageIndex = lazy(() => import('./balance/payment-history'));
export const MiBanca = lazy(() => import('./mi-cuenta-bancaria'));
export const Domains = lazy(() => import('./domains'));
export const SuscripcionPageIndex = lazy(() => import('./suscripcion'));
export const PlansPageIndex = lazy(() => import('./suscripcion/plans'));
export const PremiumPageIndex = lazy(() => import('./suscripcion/plans/premium'));
