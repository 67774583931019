import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import './Admin/styles/App.scss';
import 'materialize-css';
import 'materialize-css/sass/materialize.scss';
import { Login, Dashboard, OrderStatus, PerfilEmpresa, Mensajes, Carrusel, Cotizaciones, Cotizacion, Visitas, Catalogo, NewProduct, EditProduct, WebConfigs, AvisoPrivacidad, Faq, Glosario, PoliticaCompra, PagosEnvios, Pickups, Comentarios, ComentariosList } from './Admin/components'
import { DropShipingPageIndex, CatalogoGlobalPageIndex, VentasPageIndex, EntregaPageIndex, BalanceSummaryPageIndex, PaymentHistoryPageIndex, MiBanca, Domains, SuscripcionPageIndex, PlansPageIndex, PremiumPageIndex } from './Admin/pages'
import { UserProvider } from './Admin/contexts/UserContext';
import { DataProvider } from './Shop/contexts/DataContext';
import { useTranslation } from 'react-i18next'
const Home = React.lazy(() => import('./Shop/components/Home'))
const SearchPage = React.lazy(() => import('./Shop/pages/search'))
const KeywordPage = React.lazy(() => import('./Shop/pages/search/keyword'))
const LoginPage = React.lazy(() => import('./Shop/pages/login'))
const MiPerfilPageLayout = React.lazy(() => import('./Shop/pages/miperfil'))
const MisDireccionesPage = React.lazy(() => import('./Shop/pages/miperfil/misdirecciones'))
const MisDatosPage = React.lazy(() => import('./Shop/pages/miperfil/datos'))
const RegistroPage = React.lazy(() => import('./Shop/pages/registro'))
const PedidosPage = React.lazy(() => import('./Shop/pages/pedidos'))
const PedidoPage = React.lazy(() => import('./Shop/pages/pedidos/pedido'))
const MessagesPage = React.lazy(() => import('./Shop/pages/messages'))
const MiCarritoPage = React.lazy(() => import('./Shop/pages/micarrito'))
const MiCarritoDireccionPage = React.lazy(() => import('./Shop/pages/micarrito/direccion'))
const MiCarritoEnvioPage = React.lazy(() => import('./Shop/pages/micarrito/envio'))
const MiCarritoPagoPage = React.lazy(() => import('./Shop/pages/micarrito/pago'))
const MiCarritoFinalizaPage = React.lazy(() => import('./Shop/pages/micarrito/finaliza'))
const QuienesSomosPage = React.lazy(() => import('./Shop/pages/somos'))
const AvisoPage = React.lazy(() => import('./Shop/pages/aviso'))
const BolsaPage = React.lazy(() => import('./Shop/pages/bolsa_trabajo'))
const FaqPage = React.lazy(() => import('./Shop/pages/faq'))
const GlosarioPage = React.lazy(() => import('./Shop/pages/glosario'))
const PoliticasCompras = React.lazy(() => import('./Shop/pages/politicas_compras'))
const PagosEnviosPage = React.lazy(() => import('./Shop/pages/pagos_envios'))
const Error404Page = React.lazy(() => import('./Shop/pages/404'))
const ProductPage = React.lazy(() => import('./Shop/pages/product'))
const ProveedorPageLayout = React.lazy(() => import('./Shop/pages/proveedor/layout'))
const ProveedorPageIndex = React.lazy(() => import('./Shop/pages/proveedor'))
const CatPage = React.lazy(() => import('./Shop/pages/cat'))
const DepsPage = React.lazy(() => import('./Shop/pages/deps'))
const DepsDepartamentoPage = React.lazy(() => import('./Shop/pages/deps/departamento'))
const RecuperarPage = React.lazy(() => import('./Shop/pages/recuperar'))
const RecuperarTokenPage = React.lazy(() => import('./Shop/pages/recuperar/token'))
const AboutusPageLayout = React.lazy(() => import('./Shop/pages/aboutus/layout'))
const AboutusPageIndex = React.lazy(() => import('./Shop/pages/aboutus'))
const AccountSuspendedPageIndex = React.lazy(() => import('./Shop/pages/account_suspended'))
const ReviewsPageIndex = React.lazy(() => import('./Shop/pages/reviews'))

const ScrollToUp = () => {
	const { pathname, search } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);

	}, [pathname, search]);
	return null
}

const App=()=>{

	const { t } = useTranslation()

	const Hola=()=>{
		return (
			<div className="card-panel">
				<h4>{t('homePage')} / {t('bienvenido_a_tu_panel')}</h4>
				<div className='row'style={{display:'flex', alignItems:'center'}}>
					<div className='col'>
						<h4>{t('tu_pagina_es')}: {window.location.hostname.replace('www.','')}</h4>
					</div>
					<div className='col'>
						<a href={`https://${window.location.hostname.replace('www.','')}`} target='_blank' className='btn waves-effect waves-light grey'>{t('abrir')}</a>
					</div>
				</div>
				<div>
					<p>{t('para_configurar_tu_dominio')}</p>
				</div>
			</div>
		)
	}

	const Not = () => {
		return(
				<>
				Not found
				</>
		)
		
	}

	return (
		<Router scrollRestoration="auto">
		<Suspense fallback={<div>Loading...</div>}>
			<ScrollToUp />
			{/*Shop App*/}
			<DataProvider>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/search" element={<SearchPage />} />
					<Route path="/search/keyword" element={<KeywordPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/registro" element={<RegistroPage />} />
					<Route path="/miperfil" element={<MiPerfilPageLayout />} >
						<Route path="misdirecciones" element={<MisDireccionesPage />} />
						<Route path="datos" element={<MisDatosPage />} />
					</Route>
					<Route path="/product" element={<ProductPage />} />
					<Route path="/pedidos" element={<PedidosPage />} />
					<Route path="/pedidos/pedido" element={<PedidoPage />} />
					<Route path="/messages" element={<MessagesPage />} />
					<Route path="/micarrito" element={<MiCarritoPage />} />
					<Route path="/micarrito/direccion" element={<MiCarritoDireccionPage />} />
					<Route path="/micarrito/envio" element={<MiCarritoEnvioPage />} />
					<Route path="/micarrito/pago" element={<MiCarritoPagoPage />} />
					<Route path="/micarrito/finaliza" element={<MiCarritoFinalizaPage />} />
					<Route path="/somos" element={<QuienesSomosPage />} />
					<Route path="/aviso" element={<AvisoPage />} />
					<Route path="/bolsa_trabajo" element={<BolsaPage />} />
					<Route path="/faq" element={<FaqPage />} />
					<Route path="/glosario" element={<GlosarioPage />} />
					<Route path="/politicas_compras" element={<PoliticasCompras />} />
					<Route path="/pagos_envios" element={<PagosEnviosPage />} />
					<Route path="/404" element={<Error404Page />} />
					<Route path="/proveedor" element={<ProveedorPageLayout />} >
						<Route index element={<ProveedorPageIndex />} />
					</Route>
					<Route path="/cat" element={<CatPage />} />
					<Route path="/deps" element={<DepsPage />} />
					<Route path="/deps/departamento" element={<DepsDepartamentoPage />} />
					<Route path="/recuperar" element={<RecuperarPage />} />
					<Route path="/recuperar/token" element={<RecuperarTokenPage />} />
					<Route path="/aboutus" element={<AboutusPageLayout />} >
						<Route index element={<AboutusPageIndex />} />
					</Route>
					<Route path="account_suspended" element={<AccountSuspendedPageIndex />} />
					<Route path="reviews" element={<ReviewsPageIndex />} />
				</Routes>
			</DataProvider>

			{/*Admin App*/}
			<UserProvider>
				<Routes>
					<Route path="admin/login" element={<Login />} />
					<Route path='admin' element={<Dashboard />}>
						<Route index element={<Hola />} />
						<Route path="orderStatus" element={<OrderStatus />} />
						<Route path="catalogo" element={<Catalogo />} />
						{/*pedidos*/}
						<Route path="ventas" element={<VentasPageIndex />} />
						{/*e-commerce*/}
						<Route path="altaproducto" element={<NewProduct />} />
						<Route path="catalogo/producto/:catalogo_id" element={<EditProduct />} />
						<Route path="catalogo/comentarios/:codigo" element={<Comentarios/>} />
						<Route path="mis-datos" element={<PerfilEmpresa />} />
						<Route path="entrega/:pedido_id" element={<EntregaPageIndex />} />
						{/*Mensajes*/}
						<Route path="mensajes" element={<Mensajes />} />
						{/*Cotizaciones*/}
						<Route path="cotizaciones" element={<Cotizaciones />} />
						<Route path="cotizacion/:cotizacion_id" element={<Cotizacion />} />
						{/*Configuracion*/}
						<Route path="mi-tienda" element={<Carrusel />} />
						<Route path="website" element={<WebConfigs />} />
						{/*Visitas*/}
						<Route path="visitas" element={<Visitas />} />
						{/*Aviso Privacidad*/}
						<Route path="aviso-privacidad" element={<AvisoPrivacidad />} />
						{/*Faq*/}
						<Route path="faq" element={<Faq />} />
						{/*Glosario*/}
						<Route path="glosario" element={<Glosario />} />
						{/*Politicas*/}
						<Route path="politica-compra" element={<PoliticaCompra />} />
						<Route path="pagos-y-envios" element={<PagosEnvios />} />
						{/*Pickups*/}
						<Route path="pickups" element={<Pickups />} />
						{/*DropShiping*/}
						<Route path="dropshiping" element={<DropShipingPageIndex />} />
						<Route path="catalogoglobal" element={<CatalogoGlobalPageIndex />} />
						{/*Saldos*/}
						<Route path="balance/summary" element={<BalanceSummaryPageIndex />} />
						<Route path="balance/payment-history" element={<PaymentHistoryPageIndex />} />
						{/*My cuenta bancario*/}
						<Route path="my-account-bank" element={<MiBanca />} />
						{/*My Domains*/}
						<Route path="domains" element={<Domains />} />
						{/*Suscripcion*/}
						<Route path="suscripcion" element={<SuscripcionPageIndex />} />
						<Route path="suscripcion/plans" element={<PlansPageIndex />} />
						<Route path="suscripcion/plans/premium" element={<PremiumPageIndex />} />
						{/*Comments*/}
						<Route path="comments" element={<ComentariosList />} />
					</Route>
				</Routes>
			</UserProvider>
		</Suspense>
		</Router>
	);
}

export default App;
